import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {CoachCard} from "src/components/interfaces/CoachCard";
import {Competition} from "src/components/interfaces/Competition";
import {PageLayout} from "src/components/page-layout";
import {useGlobal} from "src/contexts/GlobalContext";
import "./ListCoachCard.css";

const CoachCardTable: React.FC = () => {
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;
  const [coachData, setCoachData] = useState<CoachCard[]>([]);
  const [filteredCoachData, setFilteredCoachData] = useState<CoachCard[]>([]);
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const { userInfo } = useGlobal();
  useEffect(() => {
    fetch(`${url}/coachcard`)
      .then((response) => response.json())
      .then((coachCardData: CoachCard[]) => {
        if (userInfo.role === "clube") {
          setCoachData(coachCardData.filter((x) => x.Clube === userInfo.clube));
        }
        if (userInfo.role === "referee") {
          setCoachData(
            coachCardData.filter(
              (x) => x.State === "Verificado" || x.State === "Submetido"
            )
          );
        }
        if (userInfo.role === "admin") {
          setCoachData(coachCardData);
          setFilteredCoachData(coachCardData);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(`${url}/competitions`)
      .then((response) => response.json())
      .then((competitionData: Competition[]) => {
        setCompetitions(competitionData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  function findCompetitionName(id: string) {
    if (competitions.length > 0) {
      return competitions.filter((c) => c._id === id)[0].Nome;
    }
    return "";
  }
  function deleteCoachCard(id: string | undefined): void {
    fetch(`${url}/coachcard/${id}`, { method: "DELETE" })
      .then((response) => {
        if (response.ok) {
          setCoachData(coachData.filter((x) => x._id !== id));
        } else {
          console.error("Failed to delete resource");
        }
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  }

  function editCoachCard(id: string | undefined): void {
    history.push(`/coachCard?id=${id}`);
  }

  function getCoachCard(id: string | undefined): void {
    history.push(`/refCoachCard?id=${id}`);
  }
  function getPontCoachCard(id: string | undefined): void {
    history.push(`/pontCoachCard?id=${id}`);
  }

  function resultCoachCard(id: string | undefined): void {
    history.push(`/resultCoachCard?id=${id}`);
  }
  return (
    <PageLayout>
      <select
        onChange={(e) => {
          console.log(e.target.value);
          if (e.target.value !== "Todas") {
            setFilteredCoachData(
              coachData.filter((x) => x.Competicao === e.target.value)
            );
          } else {
            setFilteredCoachData(coachData);
          }
        }}
      >
        <option key={"Todas"} value={"Todas"}>
          Todas
        </option>

        {[...new Set(coachData.map((item) => item.Competicao))]
          .map((item) =>
            competitions.find((competition) => competition._id == item)
          )
          .map((competition) => (
            <option key={competition?._id} value={competition?._id}>
              {competition?.Nome}
            </option>
          ))}
      </select>
      <table className="coach-table">
        <thead>
          <tr>
            <th>Competition</th>
            <th>Age Group</th>
            <th>Routine</th>
            <th>Team</th>
            <th>Routine's theme</th>
            <th>Total difficulty declared</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCoachData.map((coach, index) => (
            <tr key={coach._id || index}>
              <td>{findCompetitionName(coach.Competicao)}</td>
              <td>{coach.Categoria}</td>
              <td>{coach.Esquema}</td>
              <td>{coach.Clube}</td>
              <td>{coach.Tema_esquema}</td>
              <td>{coach.Total_declarado}</td>
              <td>{coach.State}</td>
              <td>
                <button
                  className="edit-button"
                  onClick={() => editCoachCard(coach._id)}
                >
                  {coach.State === "Rascunho" ? "Editar" : "Ver"}
                </button>
                <button
                  className="edit-button"
                  onClick={() => getCoachCard(coach._id)}
                >
                  Print
                </button>
                <button
                  hidden={userInfo.role === "clube"}
                  className="edit-button"
                  onClick={() => getPontCoachCard(coach._id)}
                >
                  Pontuador
                </button>
                <button
                  hidden={userInfo.role === "clube"}
                  className="edit-button"
                  onClick={() => resultCoachCard(coach._id)}
                >
                  Result
                </button>
                <button
                  hidden={userInfo.role === "referee"}
                  className="delete-button"
                  onClick={() => deleteCoachCard(coach._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </PageLayout>
  );
};

export default CoachCardTable;
