import React, {useEffect, useState} from "react";

import {PageLayout} from "src/components/page-layout";
import {useGlobal} from "src/contexts/GlobalContext";
import {useHistory} from "react-router-dom";
import {Atletas, Figura} from "src/components/interfaces/Figura";
import {Competition} from "src/components/interfaces/Competition";

const CreateFiguras: React.FC = () => {
  const [load, setLoad] = useState(false);
  const { userInfo } = useGlobal();
  const [figuraData, setFiguraData] = useState<Figura>({
    _id: "",
    Competicao: "",
    Categoria: "",
    Clube: userInfo.clube,
    Atletas: [],
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  let _compId = params.get("compId");

  useEffect(() => {
    if (_id && _id !== "") {
      fetch(`${url}/figura/${_id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: Figura) => {
          console.log(data);
          if (data) {
            setFiguraData(data);
          }
        });
    }
    if (_compId && _compId !== undefined && _compId !== "") {
      fetch(`${url}/competitions/${_compId}`)
        .then((response) => response.json())
        .then((competitionData: Competition) => {
          const comp = competitionData;
          setFiguraData({ ...figuraData, Competicao: comp._id || "error" });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;
  
 

  const addAtleta = () => {
    const newAtleta: Atletas = { Licenca: 0, Nome: "", Ano: 0 };
    setFiguraData({
      ...figuraData,
      Atletas: [...figuraData.Atletas, newAtleta],
    });
  };

  const saveFigura = () => {
    setLoad(true);
    if (_id && _id !== "") {
      fetch(`${url}/figura/${_id}`, { method: "DELETE" })
        .then((response) => {
          if (response.ok) {
          } else {
            console.error("Failed to delete resource");
          }
        })
        .catch((error) => {
          console.error("Error deleting resource:", error);
        });
    }
    delete figuraData["_id"];
    const json = JSON.stringify(figuraData);
    fetch(`${url}/figura/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: json,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert("Entries for the Figures event added successfully!");
        setLoad(false);
        history.push("/listFiguras");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function removeAtleta(index: number): void {
    setFiguraData({
      ...figuraData,
      Atletas: figuraData.Atletas.filter((x, i) => i !== index),
    });
  }

  return (
    <PageLayout>
      <h2>Add entries-Figure event</h2>
      <div className="form-container">
        <div className="form-group">
          <label>Age group:</label>
          <select
            value={figuraData.Categoria}
            onChange={(e) =>
              setFiguraData({
                ...figuraData,
                Categoria: e.target.value,
              })
            }
          >
            <option value="none">Select one</option>
            <option key="INF" value="INF">
              12 and under
            </option>
            <option key="JUV" value="JUV">
              Youth
            </option>
          </select>
        </div>
        <div className="form-group">
          <label>Team:</label>
          <input type="text" value={figuraData.Clube} disabled />
        </div>
      </div>
      <div>
        <button className={`button`} onClick={addAtleta}>
          Add Athlete
        </button>
      </div>
      <div>
        {figuraData.Atletas.length > 0 && (
          <div>
            <h3>List of Athletes:</h3>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>License</th>
                    <th>Name</th>
                    <th>Year of birth</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {figuraData.Atletas.map((atleta, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="number"
                          value={atleta.Licenca.toString()}
                          onChange={(e) => {
                            const updatedData = { ...figuraData };
                            updatedData.Atletas[index].Licenca =
                              +e.target.value;
                            setFiguraData(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={atleta.Nome}
                          style={{ width: "250px" }}
                          onChange={(e) => {
                            const updatedData = { ...figuraData };
                            updatedData.Atletas[index].Nome = e.target.value;
                            setFiguraData(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={atleta.Ano}
                          onChange={(e) => {
                            const updatedData = { ...figuraData };
                            updatedData.Atletas[index].Ano = +e.target.value;
                            setFiguraData(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <button
                          className="delete-button"
                          disabled={load}
                          onClick={() => removeAtleta(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <button className={`button`} onClick={() => saveFigura()}>
        Add entries-Figure event
      </button>
    </PageLayout>
  );
};

export default CreateFiguras;
