import React, {useEffect, useState} from "react";
import {Competition} from "src/components/interfaces/Competition";
import {PageLayout} from "src/components/page-layout";
import html2pdf from "html2pdf.js";
import "./StartList.css";

const StartListComponent: React.FC = () => {
  const url = process.env.REACT_APP_API_URL;
  const [competition, setCompetition] = useState<Competition>({
    Nome: "",
    Data: "",
    Limite_inscricao: "",
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    StartList: [],
    _id: undefined,
    Ordem: [],
    Figuras: [],
    JuizesCoachCard:undefined,
    JuizesFiguras:undefined,
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  useEffect(() => {
    if (_id && _id !== undefined && _id !== "") {
      fetch(`${url}/competitions/${_id}`)
        .then((response) => response.json())
        .then((competitionData: Competition) => {
          setCompetition(competitionData);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    //console.log(competition);
  }, []);

  const generatePdf = async () => {
    const element = document.getElementById("startlist");
    const options = {
      filename: `StartList_${competition.Nome}`,
      pagebreak: { mode: "css", before: ".page-break" },
      margin: 10,
      jsPDF: {
        format: "a4",
      },
    };
    await html2pdf().from(element).set(options).save();
  };
  return (
    <PageLayout>
      <button hidden className="button" onClick={() => generatePdf()}>
        Start List PDF
      </button>
      <div id="startlist">
        {competition.StartList.map((startList, i) => (
          <div>
            <h2>Categoria: {startList.Categoria}</h2>
            <h2>Prova: {startList.Prova}</h2>
            <table>
              <thead>
                <tr>
                  <th>Numero</th>
                  {startList.Prova !== "Figuras" && <th></th>}
                  <th>Nome</th>
                  <th>Clube</th>
                  <th>Licença</th>
                  <th>Ano</th>
                </tr>
              </thead>
              <tbody>
                {startList.Atletas_start.map((atletasStart, index) => (
                  <>
                    {atletasStart.Atletas.map((atleta, idx) => (
                      <tr key={idx}>
                        {idx !== 0 ? <td></td> : <td>{index + 1}</td>}
                        {startList.Prova !== "Figuras" && (
                          <td>{atleta.Numero}</td>
                        )}
                        <td>{atleta.Name}</td>
                        <td>{atleta.Team}</td>
                        <td>{atleta.License}</td>
                        <td>{atleta.Year}</td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
            <div style={{ height: "50px" }}></div>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default StartListComponent;
