import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {App} from "./app";
import {Auth0ProviderWithHistory} from "./auth0-provider-with-history";
import "./styles/styles.css";
import {GlobalProvider} from "./contexts/GlobalContext";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
