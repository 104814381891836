import React, {useEffect, useState} from "react";

import {Competition} from "src/components/interfaces/Competition";
import {PageLayout} from "src/components/page-layout";
import {useGlobal} from "src/contexts/GlobalContext";
import html2pdf from "html2pdf.js";
import "./ResultList.css";

const ResultListComponent: React.FC = () => {
  const url = process.env.REACT_APP_API_URL;
  const [competition, setCompetition] = useState<Competition>({
    Nome: "",
    Data: "",
    Limite_inscricao: "",
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    StartList: [],
    _id: undefined,
    Ordem: [],
    Figuras: [],
    JuizesCoachCard: undefined,
    JuizesFiguras: undefined,
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("id");
  useEffect(() => {
    if (_id && _id !== undefined && _id !== "") {
      fetch(`${url}/competitions/${_id}`)
        .then((response) => response.json())
        .then((competitionData: Competition) => {
          setCompetition(competitionData);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    //console.log(competition);
  }, []);

  const { userInfo } = useGlobal();

  const generatePdf = async () => {
    const element = document.getElementById("startlist");
    const options = {
      filename: `StartList_${competition.Nome}`,
      pagebreak: { mode: "css", before: ".page-break" },
      margin: 10,
      jsPDF: {
        format: "a4",
      },
    };
    await html2pdf().from(element).set(options).save();
  };
  return (
    <PageLayout>
      <button hidden className="button" onClick={() => generatePdf()}>
        Start List PDF
      </button>
      <div id="startlist">
        {competition.Ordem.filter((o) => o.Resultado).map((ord, i) => (
          <div>
            <h2>Categoria: {ord.Categoria}</h2>
            <h2>Prova: {ord.Tipo}</h2>
            {ord.Tipo === "Figuras" && (
              <table>
                <thead>
                  <tr>
                    <th>Pos</th>
                    <th>Nome</th>
                    <th>Clube</th>
                    <th>Figuras</th>
                    <th>Juizes</th>
                    <th>Penalizações</th>
                    <th>Total Figura</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {competition.Resultados.ResultadosFiguras.filter(
                    (c) => c.Categoria === ord.Categoria
                  )
                    .sort((a, b) => {
                      if (!a.Extra && !b.Extra) {
                        if (a.Pontos !== b.Pontos) {
                          return b.Pontos - a.Pontos; // Sort in descending order by Pontos
                        }
                        return 0;
                      }
                      if (a.Extra && !b.Extra) {
                        return 1;
                      }

                      if (!a.Extra && b.Extra) {
                        return -1;
                      }
                      if (a.Extra && b.Extra) {
                        if (a.Pontos !== b.Pontos) {
                          return b.Pontos - a.Pontos; // Sort in descending order by Pontos
                        }
                      }
                      return 0;
                    })
                    .map((result, i) =>
                      result.Resultados_fig.map((fig, index) => (
                        <>
                          <tr key={index}>
                            {index === 0 ? (
                              <>
                                <td>{result.Extra ? "Extra" : i + 1}</td>
                                <td>
                                  {result.Atleta.Nome +
                                    "-" +
                                    result.Atleta.Licenca}
                                </td>
                                <td>{result.Clube}</td>
                              </>
                            ) : (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                            <td>{fig.Name + "(" + fig.FP_val + ")"}</td>
                            <td>
                              {fig.J1 +
                              " " +
                              fig.J2 +
                              " " +
                              fig.J3 +
                              " " +
                              fig.J4 +
                              " " +
                              fig.J5 +
                              (competition.JuizesFiguras &&
                                competition.JuizesFiguras >= 6)
                                ? " " + fig.J6
                                : "" +
                                  (competition.JuizesFiguras &&
                                    competition.JuizesFiguras === 7)
                                ? " " + fig.J7
                                : ""}
                            </td>
                            <td>{fig.Penalties}</td>
                            <td>{fig.Pontos}</td>
                            {index === 0 ? (
                              <>
                                <td>{result.Pontos.toFixed(4)}</td>
                              </>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        </>
                      ))
                    )}
                </tbody>
              </table>
            )}
            {ord.Tipo !== "Figuras" && (
              <table>
                <thead>
                  <tr>
                    <th>Pos</th>
                    <th>Nome</th>
                    <th>Clube</th>
                    <th>Elementos</th>
                    <th>Juizes</th>
                    <th>Total Elementos</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {competition.Resultados.ResultadosCC.filter(
                    (c) =>
                      c.Categoria === ord.Categoria && c.Esquema === ord.Tipo
                  )
                    .sort((a, b) => {
                      if (!a.Extra && !b.Extra) {
                        if (a.Valor_total !== b.Valor_total) {
                          return b.Valor_total - a.Valor_total; // Sort in descending order by Pontos
                        }
                        return 0;
                      }
                      if (a.Extra && !b.Extra) {
                        return 1;
                      }

                      if (!a.Extra && b.Extra) {
                        return -1;
                      }
                      if (a.Extra && b.Extra) {
                        if (a.Valor_total !== b.Valor_total) {
                          return b.Valor_total - a.Valor_total; // Sort in descending order by Pontos
                        }
                        return 0;
                      }
                      return 0;
                    })
                    .map((result, i) => {
                      const result_el = result.Resultados_el.map(
                        (el, index) => (
                          <>
                            <tr key={index}>
                              {index === 0 ? (
                                <td>{result.Extra ? "Extra" : i + 1}</td>
                              ) : (
                                <td></td>
                              )}
                              <td>
                                {result.Atletas[index]
                                  ? result.Atletas[index].Nome +
                                    "-" +
                                    result.Atletas[index].Licenca
                                  : ""}
                              </td>
                              {index === 0 ? (
                                <td>{result.Clube}</td>
                              ) : (
                                <td></td>
                              )}
                              <td>{el.Elemento + "(" + el.FP_val + ")"}</td>
                              <td>
                                {el.J1.toFixed(2) +
                                " " +
                                el.J2.toFixed(2) +
                                " " +
                                el.J3.toFixed(2) +
                                " " +
                                el.J4.toFixed(2) +
                                " " +
                                el.J5.toFixed(2) +
                                (competition.JuizesCoachCard &&
                                  competition.JuizesCoachCard >= 6)
                                  ? " " + el.J6.toFixed(2)
                                  : "" +
                                    (competition.JuizesCoachCard &&
                                      competition.JuizesCoachCard === 7)
                                  ? " " + el.J7.toFixed(2)
                                  : ""}
                              </td>
                              <td>{el.Pontos.toFixed(4)}</td>
                              {index === 0 ? (
                                <>
                                  <td>{result.Valor_total.toFixed(4)}</td>
                                </>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          </>
                        )
                      );
                      const penalty = (
                        <tr key="Penalizações">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Penalizações:</td>
                          <td>{result.Penalty.Selected}</td>
                          <td>
                            {(
                              result.Penalty.Selected * result.Penalty.Value
                            ).toFixed(4)}
                          </td>
                          <td></td>
                        </tr>
                      );
                      const erros =
                        result.Categoria === "MAS" ? null : (
                          <>
                            <tr key="Erros Simples">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>Erros Simples:</td>
                              <td>{result.Erros.Simples}</td>
                              <td>{(result.Erros.Simples * 0.1).toFixed(4)}</td>
                              <td></td>
                            </tr>
                            <tr key="Erros Obvio">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>Erros Obvio:</td>
                              <td>{result.Erros.Obvio}</td>
                              <td>{(result.Erros.Obvio * 0.5).toFixed(4)}</td>
                              <td></td>
                            </tr>
                            <tr key="Erros Maior">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>Erros Maior:</td>
                              <td>{result.Erros.Maior}</td>
                              <td>{(result.Erros.Maior * 3).toFixed(4)}</td>
                              <td></td>
                            </tr>
                          </>
                        );
                      const result_coreo = result.Resultado_Coreografia.map(
                        (coreo, index) => (
                          <>
                            <tr key={index}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{coreo.Name + "(" + coreo.FP_val + ")"}</td>
                              <td>
                                {
                                  coreo.J1.toFixed(2) +
                                    " " +
                                    coreo.J2.toFixed(2) +
                                    " " +
                                    coreo.J3.toFixed(2) +
                                    " " +
                                    coreo.J4.toFixed(2) +
                                    " " +
                                    coreo.J5.toFixed(2) +
                                    (competition.JuizesCoachCard &&
                                      competition.JuizesCoachCard >= 6)
                                      ? " " + coreo.J6.toFixed(2)
                                      : "" +
                                        (competition.JuizesCoachCard &&
                                          competition.JuizesCoachCard === 7)
                                      ? " " + coreo.J7.toFixed(2)
                                      : ""}
                              </td>
                              <td>{coreo.Pontos.toFixed(4)}</td>
                              <td></td>
                            </tr>
                          </>
                        )
                      );
                      const penalties = (
                        <tr key="Penalizações">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Penalizações:</td>
                          <td>
                            {result.Penalties.filter((p) => p.Selected > 0)
                              .map((a) => a.Name + "(" + a.Selected + ")")
                              .join(" ")}
                          </td>
                          <td>
                            {result.Penalties.reduce(
                              (sum, current) =>
                                sum + current.Selected * current.Value,
                              0
                            ).toFixed(4)}
                          </td>
                          <td></td>
                        </tr>
                      );
                      return [
                        result_el,
                        penalty,
                        erros,
                        result_coreo,
                        penalties,
                      ];
                    })}
                </tbody>
              </table>
            )}
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default ResultListComponent;
