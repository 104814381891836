export const getCoeficiente = (
    esquema: string,
    elemento: string,
    categoria: string
) => {
    switch (elemento) {
        case "ACR":
            switch (categoria) {
                case "INF":
                    return esquema === "DUETO LIVRE" ? 1.6 :
                        esquema === "DUETO MISTO LIVRE" ? 1.4 :
                            esquema === "EQUIPA LIVRE" ? 0.7 :
                                esquema === "COMBINADO" ? 0.7 : 0;
                case "JUV":
                    return esquema === "DUETO LIVRE" ? 1.3 :
                        esquema === "DUETO MISTO LIVRE" ? 1.2 :
                            esquema === "EQUIPA LIVRE" ? 0.6 :
                                esquema === "COMBINADO" ? 0.5 : 0;
                case "JUN":
                case "ABS":
                    return esquema === "DUETO LIVRE" ? 0.8 :
                        esquema === "DUETO MISTO LIVRE" ? 1.0 :
                            esquema === "EQUIPA LIVRE" ? 0.6 :
                                esquema === "EQUIPA ACROBÁTICA" ? 0.6 :
                                    esquema === "EQUIPA TÉCNICA" ? 0.8 :
                                        esquema === "DUETO MISTO TECNICO" ? 0.8 : 0;
            }
            return 0;
        case "HIB":
        case "ChoHy":
            switch (categoria) {
                case "INF":
                    return esquema === "SOLO LIVRE" ? 0.5 :
                        esquema === "DUETO LIVRE" ? 0.5 :
                            esquema === "DUETO MISTO LIVRE" ? 0.5 :
                                esquema === "EQUIPA LIVRE" ? 0.7 :
                                    esquema === "COMBINADO" ? 0.6 : 0;
                case "JUV":
                    return esquema === "SOLO LIVRE" ? 0.4 :
                        esquema === "DUETO LIVRE" ? 0.3 :
                            esquema === "DUETO MISTO LIVRE" ? 0.3 :
                                esquema === "EQUIPA LIVRE" ? 0.4 :
                                    esquema === "COMBINADO" ? 0.4 : 0;
                case "JUN":
                case "ABS":
                    return esquema === "SOLO LIVRE" ? 0.3 :
                        esquema === "DUETO LIVRE" ? 0.3 :
                            esquema === "DUETO MISTO LIVRE" ? 0.3 :
                                esquema === "EQUIPA LIVRE" ? 0.3 :
                                    esquema === "EQUIPA TÉCNICA" ? 0.2 :
                                        esquema === "DUETO TECNICO" ? 0.2 :
                                            esquema === "DUETO MISTO TECNICO" ? 0.2 :
                                                esquema === "SOLO TECNICO" ? 0.2 : 0;
            }
            return 0;
        case "TRE":
            switch (categoria) {
                case "INF":
                case "JUV":
                    return 0;
                case "JUN":
                case "ABS":
                    return esquema === "DUETO TECNICO" ? 1.1 :
                        esquema === "DUETO MISTO TECNICO" ? 1 :
                            esquema === "SOLO TECNICO" ? 1 : 0;
            }
            return 0;
        case "ChMu":
            switch (categoria) {
                case "INF":
                    return esquema === "SOLO LIVRE" ? 1.4 :
                        esquema === "DUETO LIVRE" ? 1.2 :
                            esquema === "DUETO MISTO LIVRE" ? 1.6 :
                                esquema === "EQUIPA LIVRE" ? 1.2 :
                                    esquema === "COMBINADO" ? 1.8 : 1;
                case "JUV":
                    return esquema === "SOLO LIVRE" ? 1.4 :
                        esquema === "DUETO LIVRE" ? 1.2 :
                            esquema === "DUETO MISTO LIVRE" ? 1.8 :
                                esquema === "EQUIPA LIVRE" ? 1.4 :
                                    esquema === "COMBINADO" ? 1.8 : 1;
                case "JUN":
                case "ABS":
                    return esquema === "SOLO LIVRE" ? 1.4 :
                        esquema === "DUETO LIVRE" ? 1.6 :
                            esquema === "DUETO MISTO LIVRE" ? 2.2 :
                                esquema === "EQUIPA LIVRE" ? 1.8 :
                                    esquema === "EQUIPA ACROBÁTICA" ? 1.2 :
                                        esquema === "EQUIPA TÉCNICA" ? 1.5 :
                                            esquema === "DUETO TECNICO" ? 1.6 :
                                                esquema === "DUETO MISTO TECNICO" ? 1.3 :
                                                    esquema === "SOLO TECNICO" ? 1.4 : 1;
            }
            return 1;
        case "Performance":
            switch (categoria) {
                case "INF":
                    return esquema === "SOLO LIVRE" ? 1.2 :
                        esquema === "DUETO LIVRE" ? 1.0 :
                            esquema === "DUETO MISTO LIVRE" ? 1.4 :
                                esquema === "EQUIPA LIVRE" ? 1.0 :
                                    esquema === "COMBINADO" ? 1.6 : 1;
                case "JUV":
                    return esquema === "SOLO LIVRE" ? 1.2 :
                        esquema === "DUETO LIVRE" ? 1.0 :
                            esquema === "DUETO MISTO LIVRE" ? 1.6 :
                                esquema === "EQUIPA LIVRE" ? 1.2 :
                                    esquema === "COMBINADO" ? 1.6 : 1;
                case "JUN":
                case "ABS":
                    return esquema === "SOLO LIVRE" ? 1.2 :
                        esquema === "DUETO LIVRE" ? 1.4 :
                            esquema === "DUETO MISTO LIVRE" ? 2.0 :
                                esquema === "EQUIPA LIVRE" ? 1.6 :
                                    esquema === "EQUIPA TÉCNICA" ? 1.3 :
                                        esquema === "DUETO TECNICO" ? 1.4 :
                                            esquema === "DUETO MISTO TECNICO" ? 1.1 :
                                                esquema === "SOLO TECNICO" ? 1.2 : 1;
            }
            return 1;
        case "Transições":
            switch (categoria) {
                case "INF":
                case "JUV":
                    return esquema === "SOLO LIVRE" ? 1.4 :
                        esquema === "DUETO LIVRE" ? 1.2 :
                            esquema === "DUETO MISTO LIVRE" ? 1.6 :
                                esquema === "EQUIPA LIVRE" ? 1.2 :
                                    esquema === "COMBINADO" ? 1.8 : 1;
                case "JUN":
                case "ABS":
                    return esquema === "SOLO LIVRE" ? 1.4 :
                        esquema === "DUETO LIVRE" ? 1.6 :
                            esquema === "DUETO MISTO LIVRE" ? 2.2 :
                                esquema === "EQUIPA LIVRE" ? 1.8 :
                                    esquema === "DUETO TECNICO" ? 1.6 :
                                        esquema === "DUETO MISTO TECNICO" ? 1.3 :
                                            esquema === "SOLO TECNICO" ? 1.4 : 1;
            }
            return 1;
    }
    return 1;
};