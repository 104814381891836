import {Coeficientes} from "src/components/interfaces/CoachCard";

export const bonus: Coeficientes[] = [
  { Codigo_cc: "1PC", Valor: 0.2 },
  { Codigo_cc: "2PC", Valor: 0.4 },
  { Codigo_cc: "3PC", Valor: 0.6 },
  { Codigo_cc: "4PC", Valor: 0.8 },
  { Codigo_cc: "5PC", Valor: 1.0 },
  { Codigo_cc: "6PC", Valor: 1.2 },
  { Codigo_cc: "7PC", Valor: 1.4 },
  { Codigo_cc: "8PC", Valor: 1.6 },
  { Codigo_cc: "9PC", Valor: 1.8 },
  { Codigo_cc: "10PC", Valor: 2.0 },
  { Codigo_cc: "11PC", Valor: 2.2 },
  // { Codigo_cc: "1PC*0.5", Valor: 0.1 },
  // { Codigo_cc: "2PC*0.5", Valor: 0.2 },
  // { Codigo_cc: "3PC*0.5", Valor: 0.3 },
  // { Codigo_cc: "4PC*0.5", Valor: 0.4 },
  // { Codigo_cc: "5PC*0.5", Valor: 0.5 },
  // { Codigo_cc: "6PC*0.5", Valor: 0.6 },
  // { Codigo_cc: "1PC*0.3", Valor: 0.06 },
  // { Codigo_cc: "2PC*0.3", Valor: 0.12 },
  // { Codigo_cc: "3PC*0.3", Valor: 0.18 },
  // { Codigo_cc: "4PC*0.3", Valor: 0.24 },
  // { Codigo_cc: "5PC*0.3", Valor: 0.3 },
  // { Codigo_cc: "6PC*0.3", Valor: 0.36 },
];

export const coefHibridas: Coeficientes[] = [
  // T-values
  { Codigo_cc: "TB", Valor: 0.3 },
  { Codigo_cc: "T1", Valor: 0.45 },
  { Codigo_cc: "T2a", Valor: 0.5 },
  { Codigo_cc: "T2b", Valor: 0.5 },
  { Codigo_cc: "T3a", Valor: 0.65 },
  { Codigo_cc: "T3b", Valor: 0.65 },
  { Codigo_cc: "T3c", Valor: 0.65 },
  { Codigo_cc: "T3d", Valor: 0.65 },
  { Codigo_cc: "T4a", Valor: 0.8 },
  { Codigo_cc: "T4b", Valor: 0.8 },
  { Codigo_cc: "T4c", Valor: 0.8 },
  { Codigo_cc: "T4d", Valor: 0.8 },
  { Codigo_cc: "T4e", Valor: 0.8 },
  { Codigo_cc: "T5a", Valor: 0.9 },
  { Codigo_cc: "T5b", Valor: 0.9 },
  { Codigo_cc: "T5c", Valor: 0.9 },
  { Codigo_cc: "T5d", Valor: 0.9 },
  { Codigo_cc: "T5e", Valor: 0.9 },
  { Codigo_cc: "T6a", Valor: 1.1 },
  { Codigo_cc: "T6b", Valor: 1.1 },
  { Codigo_cc: "T6c", Valor: 1.1 },
  { Codigo_cc: "T7", Valor: 1.5 },
  { Codigo_cc: "T8", Valor: 1.7 },
  { Codigo_cc: "T9a", Valor: 2.0 },
  { Codigo_cc: "T9b", Valor: 2.0 },
  { Codigo_cc: "TB*0.3", Valor: 0.09 },
  { Codigo_cc: "T1*0.3", Valor: 0.14 },
  { Codigo_cc: "T2a*0.3", Valor: 0.15 },
  { Codigo_cc: "T2b*0.3", Valor: 0.15 },
  { Codigo_cc: "T3a*0.3", Valor: 0.2 },
  { Codigo_cc: "T3b*0.3", Valor: 0.2 },
  { Codigo_cc: "T3c*0.3", Valor: 0.2 },
  { Codigo_cc: "T3d*0.3", Valor: 0.2 },
  { Codigo_cc: "T4a*0.3", Valor: 0.24 },
  { Codigo_cc: "T4b*0.3", Valor: 0.24 },
  { Codigo_cc: "T4c*0.3", Valor: 0.24 },
  { Codigo_cc: "T4d*0.3", Valor: 0.24 },
  { Codigo_cc: "T4e*0.3", Valor: 0.24 },
  { Codigo_cc: "T5a*0.3", Valor: 0.27 },
  { Codigo_cc: "T5b*0.3", Valor: 0.27 },
  { Codigo_cc: "T5c*0.3", Valor: 0.27 },
  { Codigo_cc: "T5d*0.3", Valor: 0.27 },
  { Codigo_cc: "T5e*0.3", Valor: 0.27 },
  { Codigo_cc: "T6a*0.3", Valor: 0.33 },
  { Codigo_cc: "T6b*0.3", Valor: 0.33 },
  { Codigo_cc: "T6c*0.3", Valor: 0.33 },
  { Codigo_cc: "T7*0.3", Valor: 0.45 },
  { Codigo_cc: "T8*0.3", Valor: 0.51 },
  { Codigo_cc: "T9a*0.3", Valor: 0.6 },
  { Codigo_cc: "T9b*0.3", Valor: 0.6 },
  { Codigo_cc: "TB*0.5", Valor: 0.15 },
  { Codigo_cc: "T1*0.5", Valor: 0.23 },
  { Codigo_cc: "T2a*0.5", Valor: 0.25 },
  { Codigo_cc: "T2b*0.5", Valor: 0.25 },
  { Codigo_cc: "T3a*0.5", Valor: 0.33 },
  { Codigo_cc: "T3b*0.5", Valor: 0.33 },
  { Codigo_cc: "T3c*0.5", Valor: 0.33 },
  { Codigo_cc: "T3d*0.5", Valor: 0.33 },
  { Codigo_cc: "T4a*0.5", Valor: 0.4 },
  { Codigo_cc: "T4b*0.5", Valor: 0.4 },
  { Codigo_cc: "T4c*0.5", Valor: 0.4 },
  { Codigo_cc: "T4d*0.5", Valor: 0.4 },
  { Codigo_cc: "T4e*0.5", Valor: 0.4 },
  { Codigo_cc: "T5a*0.5", Valor: 0.45 },
  { Codigo_cc: "T5b*0.5", Valor: 0.45 },
  { Codigo_cc: "T5c*0.5", Valor: 0.45 },
  { Codigo_cc: "T5d*0.5", Valor: 0.45 },
  { Codigo_cc: "T5e*0.5", Valor: 0.45 },
  { Codigo_cc: "T6a*0.5", Valor: 0.55 },
  { Codigo_cc: "T6b*0.5", Valor: 0.55 },
  { Codigo_cc: "T6c*0.5", Valor: 0.55 },
  { Codigo_cc: "T7*0.5", Valor: 0.75 },
  { Codigo_cc: "T8*0.5", Valor: 0.85 },
  { Codigo_cc: "T9a*0.5", Valor: 1.0 },
  { Codigo_cc: "T9b*0.5", Valor: 1.0 },

  // R-values
  { Codigo_cc: "RB", Valor: 0.1 },
  { Codigo_cc: "1RB", Valor: 0.15 },
  { Codigo_cc: "2RB", Valor: 0.2 },
  { Codigo_cc: "ROB", Valor: 0.25 },
  { Codigo_cc: "RCB", Valor: 0.25 },
  { Codigo_cc: "R1", Valor: 0.2 },
  { Codigo_cc: "1R1", Valor: 0.35 },
  { Codigo_cc: "2R1", Valor: 0.45 },
  { Codigo_cc: "RD1", Valor: 0.5 },
  { Codigo_cc: "RU1", Valor: 0.55 },
  { Codigo_cc: "RO1", Valor: 0.55 },
  { Codigo_cc: "RC1", Valor: 0.55 },
  { Codigo_cc: "R2", Valor: 0.4 },
  { Codigo_cc: "1R2", Valor: 0.75 },
  { Codigo_cc: "2R2", Valor: 0.95 },
  { Codigo_cc: "RD2", Valor: 1.05 },
  { Codigo_cc: "RU2", Valor: 1.15 },
  { Codigo_cc: "R3", Valor: 0.6 },
  { Codigo_cc: "1R3", Valor: 1.15 },
  { Codigo_cc: "2R3", Valor: 1.45 },
  { Codigo_cc: "RU3", Valor: 1.75 },
  { Codigo_cc: "R4", Valor: 0.8 },
  { Codigo_cc: "1R4", Valor: 1.55 },
  { Codigo_cc: "2R4", Valor: 1.95 },
  { Codigo_cc: "RD4", Valor: 2.15 },
  { Codigo_cc: "RU4", Valor: 2.35 },
  { Codigo_cc: "1R5", Valor: 1.95 },
  { Codigo_cc: "2R5", Valor: 2.45 },
  { Codigo_cc: "RU5", Valor: 2.95 },
  { Codigo_cc: "1R6", Valor: 2.35 },
  { Codigo_cc: "2R6", Valor: 2.95 },
  { Codigo_cc: "RD6", Valor: 3.35 },
  { Codigo_cc: "RU6", Valor: 3.55 },
  { Codigo_cc: "2R7", Valor: 3.45 },
  { Codigo_cc: "RU7", Valor: 4.15 },
  { Codigo_cc: "2R8", Valor: 3.95 },
  { Codigo_cc: "RU8", Valor: 4.75 },
  { Codigo_cc: "2R9", Valor: 4.45 },
  { Codigo_cc: "RU9", Valor: 5.35 },
  { Codigo_cc: "2R10", Valor: 4.95 },
  { Codigo_cc: "RU10", Valor: 5.95 },
  { Codigo_cc: "RB*0.3", Valor: 0.03 },
  { Codigo_cc: "1RB*0.3", Valor: 0.05 },
  { Codigo_cc: "2RB*0.3", Valor: 0.06 },
  { Codigo_cc: "ROB*0.3", Valor: 0.08 },
  { Codigo_cc: "RCB*0.3", Valor: 0.08 },
  { Codigo_cc: "R1*0.3", Valor: 0.06 },
  { Codigo_cc: "1R1*0.3", Valor: 0.11 },
  { Codigo_cc: "2R1*0.3", Valor: 0.14 },
  { Codigo_cc: "RD1*0.3", Valor: 0.15 },
  { Codigo_cc: "RU1*0.3", Valor: 0.17 },
  { Codigo_cc: "RO1*0.3", Valor: 0.17 },
  { Codigo_cc: "RC1*0.3", Valor: 0.17 },
  { Codigo_cc: "R2*0.3", Valor: 0.12 },
  { Codigo_cc: "1R2*0.3", Valor: 0.23 },
  { Codigo_cc: "2R2*0.3", Valor: 0.29 },
  { Codigo_cc: "RD2*0.3", Valor: 0.32 },
  { Codigo_cc: "RU2*0.3", Valor: 0.35 },
  { Codigo_cc: "R3*0.3", Valor: 0.18 },
  { Codigo_cc: "1R3*0.3", Valor: 0.35 },
  { Codigo_cc: "2R3*0.3", Valor: 0.44 },
  { Codigo_cc: "RU3*0.3", Valor: 0.53 },
  { Codigo_cc: "R4*0.3", Valor: 0.24 },
  { Codigo_cc: "1R4*0.3", Valor: 0.47 },
  { Codigo_cc: "2R4*0.3", Valor: 0.59 },
  { Codigo_cc: "RD4*0.3", Valor: 0.65 },
  { Codigo_cc: "RU4*0.3", Valor: 0.71 },
  { Codigo_cc: "RB*0.5", Valor: 0.05 },
  { Codigo_cc: "1RB*0.5", Valor: 0.08 },
  { Codigo_cc: "2RB*0.5", Valor: 0.1 },
  { Codigo_cc: "ROB*0.5", Valor: 0.13 },
  { Codigo_cc: "RCB*0.5", Valor: 0.13 },
  { Codigo_cc: "R1*0.5", Valor: 0.1 },
  { Codigo_cc: "1R1*0.5", Valor: 0.18 },
  { Codigo_cc: "2R1*0.5", Valor: 0.23 },
  { Codigo_cc: "RD1*0.5", Valor: 0.25 },
  { Codigo_cc: "RU1*0.5", Valor: 0.28 },
  { Codigo_cc: "RO1*0.5", Valor: 0.28 },
  { Codigo_cc: "RC1*0.5", Valor: 0.28 },
  { Codigo_cc: "R2*0.5", Valor: 0.2 },
  { Codigo_cc: "1R2*0.5", Valor: 0.38 },
  { Codigo_cc: "2R2*0.5", Valor: 0.48 },
  { Codigo_cc: "RD2*0.5", Valor: 0.53 },
  { Codigo_cc: "RU2*0.5", Valor: 0.58 },
  { Codigo_cc: "R3*0.5", Valor: 0.3 },
  { Codigo_cc: "1R3*0.5", Valor: 0.58 },
  { Codigo_cc: "2R3*0.5", Valor: 0.73 },
  { Codigo_cc: "RU3*0.5", Valor: 0.88 },
  { Codigo_cc: "R4*0.5", Valor: 0.4 },
  { Codigo_cc: "1R4*0.5", Valor: 0.78 },
  { Codigo_cc: "2R4*0.5", Valor: 0.98 },
  { Codigo_cc: "RD4*0.5", Valor: 1.08 },
  { Codigo_cc: "RU4*0.5", Valor: 1.18 },
  { Codigo_cc: "1R5*0.5", Valor: 0.98 },
  { Codigo_cc: "2R5*0.5", Valor: 1.23 },
  { Codigo_cc: "RU5*0.5", Valor: 1.48 },
  { Codigo_cc: "1R6*0.5", Valor: 1.18 },
  { Codigo_cc: "2R6*0.5", Valor: 1.48 },
  { Codigo_cc: "RD6*0.5", Valor: 1.68 },
  { Codigo_cc: "RU6*0.5", Valor: 1.78 },
  { Codigo_cc: "2R7*0.5", Valor: 1.73 },
  { Codigo_cc: "RU7*0.5", Valor: 2.08 },
  { Codigo_cc: "2R8*0.5", Valor: 1.98 },
  { Codigo_cc: "RU8*0.5", Valor: 2.38 },
  { Codigo_cc: "2R9*0.5", Valor: 2.23 },
  { Codigo_cc: "RU9*0.5", Valor: 2.68 },
  { Codigo_cc: "2R10*0.5", Valor: 2.48 },
  { Codigo_cc: "RU10*0.5", Valor: 2.98 },

  // F-values
  { Codigo_cc: "FB", Valor: 0.05 },
  { Codigo_cc: "F1a", Valor: 0.1 },
  { Codigo_cc: "F1b", Valor: 0.1 },
  { Codigo_cc: "F1c", Valor: 0.1 },
  { Codigo_cc: "F2a", Valor: 0.2 },
  { Codigo_cc: "F2b", Valor: 0.2 },
  { Codigo_cc: "F2c", Valor: 0.2 },
  { Codigo_cc: "F3a", Valor: 0.3 },
  { Codigo_cc: "F3b", Valor: 0.3 },
  { Codigo_cc: "F3c", Valor: 0.3 },
  { Codigo_cc: "F4a", Valor: 0.4 },
  { Codigo_cc: "F4b", Valor: 0.4 },
  { Codigo_cc: "F4c", Valor: 0.4 },
  { Codigo_cc: "F4d", Valor: 0.4 },
  { Codigo_cc: "F4e", Valor: 0.4 },
  { Codigo_cc: "F4f", Valor: 0.4 },
  { Codigo_cc: "F5a", Valor: 0.5 },
  { Codigo_cc: "F5b", Valor: 0.5 },
  { Codigo_cc: "F5c", Valor: 0.5 },
  { Codigo_cc: "F6a", Valor: 0.65 },
  { Codigo_cc: "F6b", Valor: 0.65 },
  { Codigo_cc: "F6c", Valor: 0.65 },
  { Codigo_cc: "F6d", Valor: 0.65 },
  { Codigo_cc: "F7", Valor: 0.75 },
  { Codigo_cc: "F8a", Valor: 0.9 },
  { Codigo_cc: "F8b", Valor: 0.9 },
  { Codigo_cc: "F9", Valor: 1.0 },
  { Codigo_cc: "F10", Valor: 1.3 },
  { Codigo_cc: "FB*0.3", Valor: 0.02 },
  { Codigo_cc: "F1a*0.3", Valor: 0.03 },
  { Codigo_cc: "F1b*0.3", Valor: 0.03 },
  { Codigo_cc: "F1c*0.3", Valor: 0.03 },
  { Codigo_cc: "F2a*0.3", Valor: 0.06 },
  { Codigo_cc: "F2b*0.3", Valor: 0.06 },
  { Codigo_cc: "F2c*0.3", Valor: 0.06 },
  { Codigo_cc: "F3a*0.3", Valor: 0.09 },
  { Codigo_cc: "F3b*0.3", Valor: 0.09 },
  { Codigo_cc: "F3c*0.3", Valor: 0.09 },
  { Codigo_cc: "F4a*0.3", Valor: 0.12 },
  { Codigo_cc: "F4b*0.3", Valor: 0.12 },
  { Codigo_cc: "F4c*0.3", Valor: 0.12 },
  { Codigo_cc: "F4d*0.3", Valor: 0.12 },
  { Codigo_cc: "F4e*0.3", Valor: 0.12 },
  { Codigo_cc: "F4f*0.3", Valor: 0.12 },
  { Codigo_cc: "F5a*0.3", Valor: 0.15 },
  { Codigo_cc: "F5b*0.3", Valor: 0.15 },
  { Codigo_cc: "F5c*0.3", Valor: 0.15 },
  { Codigo_cc: "F6a*0.3", Valor: 0.2 },
  { Codigo_cc: "F6b*0.3", Valor: 0.2 },
  { Codigo_cc: "F6c*0.3", Valor: 0.2 },
  { Codigo_cc: "F6d*0.3", Valor: 0.2 },
  { Codigo_cc: "F7*0.3", Valor: 0.23 },
  { Codigo_cc: "F8a*0.3", Valor: 0.27 },
  { Codigo_cc: "F8b*0.3", Valor: 0.27 },
  { Codigo_cc: "F9*0.3", Valor: 0.3 },
  { Codigo_cc: "F10*0.3", Valor: 0.39 },
  { Codigo_cc: "FB*0.5", Valor: 0.03 },
  { Codigo_cc: "F1a*0.5", Valor: 0.05 },
  { Codigo_cc: "F1b*0.5", Valor: 0.05 },
  { Codigo_cc: "F1c*0.5", Valor: 0.05 },
  { Codigo_cc: "F2a*0.5", Valor: 0.1 },
  { Codigo_cc: "F2b*0.5", Valor: 0.1 },
  { Codigo_cc: "F2c*0.5", Valor: 0.1 },
  { Codigo_cc: "F3a*0.5", Valor: 0.15 },
  { Codigo_cc: "F3b*0.5", Valor: 0.15 },
  { Codigo_cc: "F3c*0.5", Valor: 0.15 },
  { Codigo_cc: "F4a*0.5", Valor: 0.2 },
  { Codigo_cc: "F4b*0.5", Valor: 0.2 },
  { Codigo_cc: "F4c*0.5", Valor: 0.2 },
  { Codigo_cc: "F4d*0.5", Valor: 0.2 },
  { Codigo_cc: "F4e*0.5", Valor: 0.2 },
  { Codigo_cc: "F4f*0.5", Valor: 0.2 },
  { Codigo_cc: "F5a*0.5", Valor: 0.25 },
  { Codigo_cc: "F5b*0.5", Valor: 0.25 },
  { Codigo_cc: "F5c*0.5", Valor: 0.25 },
  { Codigo_cc: "F6a*0.5", Valor: 0.33 },
  { Codigo_cc: "F6b*0.5", Valor: 0.33 },
  { Codigo_cc: "F6c*0.5", Valor: 0.33 },
  { Codigo_cc: "F6d*0.5", Valor: 0.33 },
  { Codigo_cc: "F7*0.5", Valor: 0.38 },
  { Codigo_cc: "F8a*0.5", Valor: 0.45 },
  { Codigo_cc: "F8b*0.5", Valor: 0.45 },
  { Codigo_cc: "F9*0.5", Valor: 0.5 },
  { Codigo_cc: "F10*0.5", Valor: 0.65 },

  // AW-values
  { Codigo_cc: "AB", Valor: 0.05 },
  { Codigo_cc: "A1a", Valor: 0.1 },
  { Codigo_cc: "A1b", Valor: 0.1 },
  { Codigo_cc: "A1c", Valor: 0.1 },
  { Codigo_cc: "A1d", Valor: 0.1 },
  { Codigo_cc: "A2a", Valor: 0.15 },
  { Codigo_cc: "A2b", Valor: 0.15 },
  { Codigo_cc: "A3a", Valor: 0.2 },
  { Codigo_cc: "A3b", Valor: 0.2 },
  { Codigo_cc: "A4a", Valor: 0.45 },
  { Codigo_cc: "A4b", Valor: 0.45 },
  { Codigo_cc: "A5", Valor: 0.65 },
  { Codigo_cc: "A6", Valor: 1.15 },
  { Codigo_cc: "A7", Valor: 1.45 },
  { Codigo_cc: "A8", Valor: 1.65 },
  { Codigo_cc: "AB*0.3", Valor: 0.02 },
  { Codigo_cc: "A1a*0.3", Valor: 0.03 },
  { Codigo_cc: "A1b*0.3", Valor: 0.03 },
  { Codigo_cc: "A1c*0.3", Valor: 0.03 },
  { Codigo_cc: "A1d*0.3", Valor: 0.03 },
  { Codigo_cc: "A2a*0.3", Valor: 0.05 },
  { Codigo_cc: "A2b*0.3", Valor: 0.05 },
  { Codigo_cc: "A3a*0.3", Valor: 0.06 },
  { Codigo_cc: "A3b*0.3", Valor: 0.06 },
  { Codigo_cc: "A4a*0.3", Valor: 0.14 },
  { Codigo_cc: "A4b*0.3", Valor: 0.14 },
  { Codigo_cc: "A5*0.3", Valor: 0.2 },
  { Codigo_cc: "A6*0.3", Valor: 0.35 },
  { Codigo_cc: "A7*0.3", Valor: 0.44 },
  { Codigo_cc: "A8*0.3", Valor: 0.5 },
  { Codigo_cc: "AB*0.5", Valor: 0.03 },
  { Codigo_cc: "A1a*0.5", Valor: 0.05 },
  { Codigo_cc: "A1b*0.5", Valor: 0.05 },
  { Codigo_cc: "A1c*0.5", Valor: 0.05 },
  { Codigo_cc: "A1d*0.5", Valor: 0.05 },
  { Codigo_cc: "A2a*0.5", Valor: 0.08 },
  { Codigo_cc: "A2b*0.5", Valor: 0.08 },
  { Codigo_cc: "A3a*0.5", Valor: 0.1 },
  { Codigo_cc: "A3b*0.5", Valor: 0.1 },
  { Codigo_cc: "A4a*0.5", Valor: 0.23 },
  { Codigo_cc: "A4b*0.5", Valor: 0.23 },
  { Codigo_cc: "A5*0.5", Valor: 0.33 },
  { Codigo_cc: "A6*0.5", Valor: 0.58 },
  { Codigo_cc: "A7*0.5", Valor: 0.73 },
  { Codigo_cc: "A8*0.5", Valor: 0.83 },

  // C-values
  { Codigo_cc: "CB", Valor: 0.1 },
  { Codigo_cc: "CB+", Valor: 0.2 },
  { Codigo_cc: "C1a", Valor: 0.2 },
  { Codigo_cc: "C1b", Valor: 0.2 },
  { Codigo_cc: "C1a+", Valor: 0.3 },
  { Codigo_cc: "C1b+", Valor: 0.3 },
  { Codigo_cc: "C2a", Valor: 0.3 },
  { Codigo_cc: "C2b", Valor: 0.3 },
  { Codigo_cc: "C2c", Valor: 0.3 },
  { Codigo_cc: "C2a+", Valor: 0.4 },
  { Codigo_cc: "C2b+", Valor: 0.4 },
  { Codigo_cc: "C2c+", Valor: 0.4 },
  { Codigo_cc: "C3", Valor: 0.4 },
  { Codigo_cc: "C3+", Valor: 0.5 },
  { Codigo_cc: "C4", Valor: 0.5 },
  { Codigo_cc: "C4+", Valor: 0.6 },
  { Codigo_cc: "C5", Valor: 1.0 },
  { Codigo_cc: "C5+", Valor: 1.1 },
  { Codigo_cc: "C6a", Valor: 1.25 },
  { Codigo_cc: "C6b", Valor: 1.25 },
  { Codigo_cc: "C6a+", Valor: 1.35 },
  { Codigo_cc: "C6b+", Valor: 1.35 },
  { Codigo_cc: "C7", Valor: 1.5 },
  { Codigo_cc: "C7+", Valor: 1.6 },
  { Codigo_cc: "CB*0,5", Valor: 0.05 },
  { Codigo_cc: "CB+*0,5", Valor: 0.1 },
  { Codigo_cc: "C1a*0,5", Valor: 0.1 },
  { Codigo_cc: "C1b*0,5", Valor: 0.1 },
  { Codigo_cc: "C1a+*0,5", Valor: 0.15 },
  { Codigo_cc: "C1b+*0,5", Valor: 0.15 },
  { Codigo_cc: "C2a*0,5", Valor: 0.15 },
  { Codigo_cc: "C2b*0,5", Valor: 0.15 },
  { Codigo_cc: "C2c*0,5", Valor: 0.15 },
  { Codigo_cc: "C2a+*0,5", Valor: 0.2 },
  { Codigo_cc: "C2b+*0,5", Valor: 0.2 },
  { Codigo_cc: "C2c+*0,5", Valor: 0.2 },
  { Codigo_cc: "C3*0,5", Valor: 0.2 },
  { Codigo_cc: "C3+*0,5", Valor: 0.25 },
  { Codigo_cc: "C4*0,5", Valor: 0.25 },
  { Codigo_cc: "C4+*0,5", Valor: 0.3 },
  { Codigo_cc: "C5*0,5", Valor: 0.5 },
  { Codigo_cc: "C5+*0,5", Valor: 0.55 },
  { Codigo_cc: "C6a*0,5", Valor: 0.63 },
  { Codigo_cc: "C6b*0,5", Valor: 0.63 },
  { Codigo_cc: "C6a+*0,5", Valor: 0.68 },
  { Codigo_cc: "C6b+*0,5", Valor: 0.68 },
  { Codigo_cc: "C7*0,5", Valor: 0.75 },
  { Codigo_cc: "C7+*0,5", Valor: 0.8 },
  { Codigo_cc: "CB*0,3", Valor: 0.03 },
  { Codigo_cc: "CB+*0,3", Valor: 0.06 },
  { Codigo_cc: "C1a*0,3", Valor: 0.06 },
  { Codigo_cc: "C1b*0,3", Valor: 0.06 },
  { Codigo_cc: "C1a+*0,3", Valor: 0.09 },
  { Codigo_cc: "C1b+*0,3", Valor: 0.09 },
  { Codigo_cc: "C2a*0,3", Valor: 0.09 },
  { Codigo_cc: "C2b*0,3", Valor: 0.09 },
  { Codigo_cc: "C2c*0,3", Valor: 0.09 },
  { Codigo_cc: "C2a+*0,3", Valor: 0.12 },
  { Codigo_cc: "C2b+*0,3", Valor: 0.12 },
  { Codigo_cc: "C2c+*0,3", Valor: 0.12 },
  { Codigo_cc: "C3*0,3", Valor: 0.12 },
  { Codigo_cc: "C3+*0,3", Valor: 0.15 },
  { Codigo_cc: "C4*0,3", Valor: 0.15 },
  { Codigo_cc: "C4+*0,3", Valor: 0.18 },
  { Codigo_cc: "C5*0,3", Valor: 0.3 },
  { Codigo_cc: "C5+*0,3", Valor: 0.33 },
  { Codigo_cc: "C6a*0,3", Valor: 0.38 },
  { Codigo_cc: "C6b*0,3", Valor: 0.38 },
  { Codigo_cc: "C6a+*0,3", Valor: 0.41 },
  { Codigo_cc: "C6b+*0,3", Valor: 0.41 },
  { Codigo_cc: "C7*0,3", Valor: 0.45 },
  { Codigo_cc: "C7+*0,3", Valor: 0.48 },

  //Spin Values
  { Codigo_cc: "SB", Valor: 0.15 },
  { Codigo_cc: "SCB", Valor: 0.35 },
  { Codigo_cc: "SCDB", Valor: 0.4 },
  { Codigo_cc: "S1", Valor: 0.35 },
  { Codigo_cc: "SC1", Valor: 0.8 },
  { Codigo_cc: "SCD1", Valor: 0.85 },
  { Codigo_cc: "S2", Valor: 0.75 },
  { Codigo_cc: "SC2", Valor: 1.6 },
  { Codigo_cc: "SCD2", Valor: 1.65 },
  { Codigo_cc: "S3", Valor: 1.15 },
  { Codigo_cc: "SC3", Valor: 2.4 },
  { Codigo_cc: "SCD3", Valor: 2.45 },
  { Codigo_cc: "S4", Valor: 1.55 },
  { Codigo_cc: "SC4", Valor: 3.2 },
  { Codigo_cc: "SCD4", Valor: 3.25 },
  { Codigo_cc: "S5", Valor: 1.95 },
  { Codigo_cc: "SC5", Valor: 4.0 },
  { Codigo_cc: "SCD5", Valor: 4.05 },
  { Codigo_cc: "S6", Valor: 2.35 },
  { Codigo_cc: "SC6", Valor: 4.8 },
  { Codigo_cc: "SCD6", Valor: 4.85 },
  { Codigo_cc: "S7", Valor: 2.75 },
  { Codigo_cc: "S8", Valor: 3.15 },
  { Codigo_cc: "S9", Valor: 3.55 },
  { Codigo_cc: "S10", Valor: 3.95 },
  { Codigo_cc: "SB*0.3", Valor: 0.05 },
  { Codigo_cc: "SCB*0.3", Valor: 0.11 },
  { Codigo_cc: "SCDB*0.3", Valor: 0.12 },
  { Codigo_cc: "S1*0.3", Valor: 0.11 },
  { Codigo_cc: "SC1*0.3", Valor: 0.24 },
  { Codigo_cc: "SCD1*0.3", Valor: 0.26 },
  { Codigo_cc: "S2*0.3", Valor: 0.23 },
  { Codigo_cc: "SC2*0.3", Valor: 0.48 },
  { Codigo_cc: "SCD2*0.3", Valor: 0.5 },
  { Codigo_cc: "S3*0.3", Valor: 0.35 },
  { Codigo_cc: "SC3*0.3", Valor: 0.72 },
  { Codigo_cc: "SCD3*0.3", Valor: 0.74 },
  { Codigo_cc: "S4*0.3", Valor: 0.47 },
  { Codigo_cc: "SC4*0.3", Valor: 0.96 },
  { Codigo_cc: "SCD4*0.3", Valor: 0.98 },
  { Codigo_cc: "S5*0.3", Valor: 0.59 },
  { Codigo_cc: "SC5*0.3", Valor: 1.2 },
  { Codigo_cc: "SCD5*0.3", Valor: 1.22 },
  { Codigo_cc: "S6*0.3", Valor: 0.71 },
  { Codigo_cc: "SC6*0.3", Valor: 1.44 },
  { Codigo_cc: "SCD6*0.3", Valor: 1.46 },
  { Codigo_cc: "S7*0.3", Valor: 0.83 },
  { Codigo_cc: "S8*0.3", Valor: 0.95 },
  { Codigo_cc: "S9*0.3", Valor: 1.07 },
  { Codigo_cc: "S10*0.3", Valor: 1.19 },
  { Codigo_cc: "SB*0.5", Valor: 0.08 },
  { Codigo_cc: "SCB*0.5", Valor: 0.18 },
  { Codigo_cc: "SCDB*0.5", Valor: 0.2 },
  { Codigo_cc: "S1*0.5", Valor: 0.18 },
  { Codigo_cc: "SC1*0.5", Valor: 0.4 },
  { Codigo_cc: "SCD1*0.5", Valor: 0.43 },
  { Codigo_cc: "S2*0.5", Valor: 0.38 },
  { Codigo_cc: "SC2*0.5", Valor: 0.8 },
  { Codigo_cc: "SCD2*0.5", Valor: 0.83 },
  { Codigo_cc: "S3*0.5", Valor: 0.58 },
  { Codigo_cc: "SC3*0.5", Valor: 1.2 },
  { Codigo_cc: "SCD3*0.5", Valor: 1.23 },
  { Codigo_cc: "S4*0.5", Valor: 0.78 },
  { Codigo_cc: "SC4*0.5", Valor: 1.6 },
  { Codigo_cc: "SCD4*0.5", Valor: 1.63 },
  { Codigo_cc: "S5*0.5", Valor: 0.98 },
  { Codigo_cc: "SC5*0.5", Valor: 2.0 },
  { Codigo_cc: "SCD5*0.5", Valor: 2.03 },
  { Codigo_cc: "S6*0.5", Valor: 1.18 },
  { Codigo_cc: "SC6*0.5", Valor: 2.4 },
  { Codigo_cc: "SCD6*0.5", Valor: 2.43 },
  { Codigo_cc: "S7*0.5", Valor: 1.38 },
  { Codigo_cc: "S8*0.5", Valor: 1.58 },
  { Codigo_cc: "S9*0.5", Valor: 1.78 },
  { Codigo_cc: "S10*0.5", Valor: 1.98 },
];

export const soloTRE: Coeficientes[] = [
  { Codigo_cc: "TRE_S_1A", Valor: 2.7 },
  { Codigo_cc: "TRE_S_1B", Valor: 2.1 },
  { Codigo_cc: "TRE_S_2A", Valor: 3.0 },
  { Codigo_cc: "TRE_S_2B", Valor: 2.7 },
  { Codigo_cc: "TRE_S_3", Valor: 3.2 },
  { Codigo_cc: "TRE_S_4A", Valor: 3.0 },
  { Codigo_cc: "TRE_S_4B", Valor: 2.5 },
  { Codigo_cc: "TRE_S_5A", Valor: 2.4 },
  { Codigo_cc: "TRE_S_5B", Valor: 2.1 },
];

export const duetoTRE: Coeficientes[] = [
  { Codigo_cc: "TRE_DT_1A", Valor: 3.0 },
  { Codigo_cc: "TRE_DT_1B", Valor: 2.5 },
  { Codigo_cc: "TRE_DT_2A", Valor: 2.8 },
  { Codigo_cc: "TRE_DT_2B", Valor: 2.4 },
  { Codigo_cc: "TRE_DT_3", Valor: 3.1 },
  { Codigo_cc: "TRE_DT_4A", Valor: 3.2 },
  { Codigo_cc: "TRE_DT_4B", Valor: 2.7 },
  { Codigo_cc: "TRE_DT_5A", Valor: 2.3 },
  { Codigo_cc: "TRE_DT_5B", Valor: 2.1 },
];

export const duetoMTRE: Coeficientes[] = [
  { Codigo_cc: "TRE_MDT_1A", Valor: 2.7 },
  { Codigo_cc: "TRE_MDT_1B", Valor: 2.5 },
  { Codigo_cc: "TRE_MDT_2A", Valor: 2.4 },
  { Codigo_cc: "TRE_MDT_2B", Valor: 2.2 },
  { Codigo_cc: "TRE_MDT_3", Valor: 3.0 },
];
export const equipaTRE: Coeficientes[] = [
  { Codigo_cc: "TRE_T_1A", Valor: 2.5 },
  { Codigo_cc: "TRE_T_1B", Valor: 2.3 },
  { Codigo_cc: "TRE_T_2A", Valor: 2.6 },
  { Codigo_cc: "TRE_T_2B", Valor: 2.3 },
  { Codigo_cc: "TRE_T_3A", Valor: 3.1 },
  { Codigo_cc: "TRE_T_3B", Valor: 2.9 },
  { Codigo_cc: "TRE_T_4", Valor: 2.9 },
  { Codigo_cc: "TRE_T_5A", Valor: 2.4 },
  { Codigo_cc: "TRE_T_5B", Valor: 2.1 },
];
