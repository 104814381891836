import React from "react";
import {NavLink} from "react-router-dom";

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/" exact>
        <img
          className="nav-bar__logo"
          src="https://www.annp.pt/themes/custom/annp_barrio/logo.png"
          alt="Annp shield logo"
        />
      </NavLink>
    </div>
  );
};
