import React, {useEffect, useState} from "react";
import {PageLayout} from "src/components/page-layout";
import {useGlobal} from "src/contexts/GlobalContext";
import {Competition, ResultadosFig, ResultadosFiguras,} from "src/components/interfaces/Competition";

const ResultFiguras: React.FC = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let _id = params.get("compId");
  const [load, setLoad] = useState(false);
  const [competition, setCompetition] = useState<Competition>({
    Nome: "",
    Data: "",
    Resultados: { ResultadosCC: [], ResultadosFiguras: [] },
    Limite_inscricao: "",
    StartList: [],
    _id: "",
    Ordem: [],
    Figuras: [],
    JuizesCoachCard: undefined,
    JuizesFiguras: undefined,
  });
  const [resultFigura, setResultFigura] = useState<ResultadosFiguras>({
    Categoria: "",
    Esquema: "Figuras",
    Clube: "",
    Number: 0,
    Atleta: {
      Licenca: 0,
      Nome: "",
      Ano: 0,
    },
    Resultados_fig: [],
    Pontos: 0,
    Extra: false,
  });

  useEffect(() => {
    fetch(`${url}/competitions/${_id}`)
      .then((response) => response.json())
      .then((competitionData: Competition) => {
        const comp = competitionData;
        setCompetition(comp);
      })
      .catch((err) => {
        console.log(err.message);
      });
    const rFigura: ResultadosFiguras = {
      Categoria: "",
      Esquema: "Figuras",
      Clube: "",
      Number: 0,
      Atleta: {
        Licenca: 0,
        Nome: "",
        Ano: 0,
      },
      Resultados_fig: [],
      Pontos: 0,
      Extra: false,
    };
    setResultFigura(rFigura);
    //console.log(competition);
  }, []);
  const url = process.env.REACT_APP_API_URL;
  const { userInfo } = useGlobal();
  const getFigura = (nome: string, value: number): ResultadosFig => {
    return {
      Name: nome,
      FP_val: value,
      J1: 0,
      J2: 0,
      J3: 0,
      J4: 0,
      J5: 0,
      J6: 0,
      J7: 0,
      Media: 0,
      Penalties: 0,
      Pontos: 0,
    };
  };

  const getPontos = (index: number): number => {
    return +(
      resultFigura.Resultados_fig[index].FP_val *
      resultFigura.Resultados_fig[index].Media
    ).toFixed(4);
  };
  const getAverage = (index: number, isImpr: boolean = false): number => {
    let values: any[] = [];
    values = [
      resultFigura.Resultados_fig[index].J1,
      resultFigura.Resultados_fig[index].J2,
      resultFigura.Resultados_fig[index].J3,
      resultFigura.Resultados_fig[index].J4,
      resultFigura.Resultados_fig[index].J5,
    ];
    if (competition.JuizesFiguras && +competition.JuizesFiguras >= 6) {
      values.push(resultFigura.Resultados_fig[index].J6);
    }
    if (competition.JuizesFiguras && +competition.JuizesFiguras === 7) {
      values.push(resultFigura.Resultados_fig[index].J7);
    }
    let sum = 0;
    let count = 0;
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    values.forEach((value) => {
      count++;
      sum += value;
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    });

    const average = (sum - min - max) / (count - 2);

    return +average.toFixed(4);
  };
  resultFigura.Pontos = +(
    (resultFigura.Resultados_fig.reduce((accumulator, object) => {
      return accumulator + object.Pontos;
    }, 0) /
      resultFigura.Resultados_fig.reduce((accumulator, object) => {
        return accumulator + Number(object.FP_val);
      }, 0)) *
      10 -
    resultFigura.Resultados_fig.reduce((accumulator, object) => {
      return accumulator + object.Penalties;
    }, 0)
  ).toFixed(4);

  function changeOrder(e: React.ChangeEvent<HTMLInputElement>): void {
    const order = +e.target.value;
    const result = competition.Resultados.ResultadosFiguras.filter(
      (r) => r.Categoria === resultFigura.Categoria && r.Number === order
    );
    if (result.length > 0) {
      setResultFigura(result[0]);
    } else {
      const prova = competition.StartList.filter(
        (c, i) =>
          c.Prova === "Figuras" && c.Categoria === resultFigura.Categoria
      )[0];
      const atleta = prova.Atletas_start[order - 1].Atletas[0];
      let figuras: ResultadosFig[] = [];
      competition.Figuras.filter(
        (f) => f.Category === resultFigura.Categoria
      ).forEach((f) => {
        figuras.push(getFigura(f.Nome, f.Valor));
      });

      setResultFigura({
        ...resultFigura,
        Atleta: {
          Nome: atleta.Name,
          Licenca: atleta.License,
          Ano: atleta.Year,
        },
        Number: order,
        Clube: atleta.Team,
        Resultados_fig: figuras,
      });
    }
  }

  const saveResultFiguras = () => {
    setLoad(true);
    fetch(`${url}/competitions/${_id}`)
      .then((response) => response.json())
      .then((competitionData: Competition) => {
        const comp = competitionData;
        comp.Resultados.ResultadosFiguras =
          comp.Resultados.ResultadosFiguras.filter(
            (c) =>
              !(
                resultFigura.Categoria === c.Categoria &&
                resultFigura.Number === c.Number
              )
          );
        comp.Resultados.ResultadosFiguras.push(resultFigura);
        return comp;
      })
      .then((comp: Competition) => deleteAndCreateComp(comp))
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteAndCreateComp = (comp: Competition) => {
    const id = comp._id;
    delete comp["_id"];
    const json = JSON.stringify(comp);
    fetch(`${url}/competitions/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: json,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCompetition({
          ...comp,
          _id: data.insertedId,
        });
        setLoad(false);
        alert("Resultado Figuras gravado com sucesso");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <PageLayout>
      <div>
        <h2>Resultados</h2>
        <h3>{competition.Nome}</h3>
        <div className="form-container">
          <div className="form-group">
            <label>Categoria:</label>
            <select
              value={resultFigura.Categoria}
              disabled={resultFigura.Number > 0}
              onChange={(e) =>
                setResultFigura({
                  ...resultFigura,
                  Categoria: e.target.value,
                })
              }
            >
              <option value="none">Selecione uma Categoria</option>
              <option key="INF" value="INF">
                INFANTIL
              </option>
              <option key="JUV" value="JUV">
                JUVENIL
              </option>
            </select>
          </div>
          <div className="form-group">
            <label>Prova:</label>
            <input type="text" value={resultFigura.Esquema} disabled />
          </div>
          <div className="form-group">
            <label>Clube:</label>
            <input type="text" value={resultFigura.Clube} disabled />
          </div>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={resultFigura.Extra}
              onChange={() =>
                setResultFigura({
                  ...resultFigura,
                  Extra: !resultFigura.Extra,
                })
              }
            />
            Extra-Competição
          </label>
        </div>
        <div>
          <div>
            <h3>Atleta:</h3>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Ordem</th>
                    <th>Licenca</th>
                    <th>Nome</th>
                    <th>Ano</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="tr">
                    <td>
                      <input
                        type="number"
                        value={resultFigura.Number}
                        disabled={resultFigura.Categoria === ""}
                        onChange={(e) => changeOrder(e)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={resultFigura.Atleta.Licenca}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={resultFigura.Atleta.Nome}
                        style={{ width: "250px" }}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={resultFigura.Atleta.Ano}
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {resultFigura.Number > 0 && (
          <div>
            <h3>Figuras:</h3>
            <div className="table-container-1">
              <table className="table-1">
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>FP</th>
                    <th style={{ width: "50px" }}>J1</th>
                    <th style={{ width: "50px" }}>J2</th>
                    <th style={{ width: "50px" }}>J3</th>
                    <th style={{ width: "50px" }}>J4</th>
                    <th style={{ width: "50px" }}>J5</th>
                    {competition.JuizesFiguras &&
                      +competition.JuizesFiguras >= 6 && (
                        <th style={{ width: "50px" }}>J6</th>
                      )}
                    {competition.JuizesFiguras &&
                      +competition.JuizesFiguras === 7 && (
                        <th style={{ width: "50px" }}>J7</th>
                      )}
                    <th hidden={resultFigura.Categoria === "JUV"}>
                      Penalizações
                    </th>
                    <th>Média</th>
                    <th>Pontuação</th>
                  </tr>
                </thead>
                <tbody>
                  {resultFigura.Resultados_fig.map((fig, index) => (
                    <tr key={index}>
                      <td className="content-fit">
                        <input type="text" value={fig.Name} disabled />
                      </td>
                      <td className="content-fit">
                        <input type="number" value={fig.FP_val} disabled />
                      </td>
                      <td className="content-fit">
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.J1.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].J1 =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      <td className="content-fit">
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.J2.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].J2 =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            console.log(
                              updatedData.Resultados_fig[index].Media
                            );
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      <td className="content-fit">
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.J3.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].J3 =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      <td className="content-fit">
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.J4.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].J4 =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      <td className="content-fit">
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.J5.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].J5 =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      {competition.JuizesFiguras &&
                        +competition.JuizesFiguras >= 6 && (
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={fig.J6.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultFigura };
                                updatedData.Resultados_fig[index].J6 =
                                  +e.target.value;
                                updatedData.Resultados_fig[index].Media =
                                  getAverage(index, true);
                                updatedData.Resultados_fig[index].Pontos =
                                  getPontos(index);
                                setResultFigura(updatedData);
                              }}
                            />
                          </td>
                        )}
                      {competition.JuizesFiguras &&
                        +competition.JuizesFiguras === 7 && (
                          <td className="content-fit">
                            <input
                              type="number"
                              min="1"
                              max="10"
                              style={{ width: "50px" }}
                              value={fig.J7.toString()}
                              onChange={(e) => {
                                const updatedData = { ...resultFigura };
                                updatedData.Resultados_fig[index].J7 =
                                  +e.target.value;
                                updatedData.Resultados_fig[index].Media =
                                  getAverage(index, true);
                                updatedData.Resultados_fig[index].Pontos =
                                  getPontos(index);
                                setResultFigura(updatedData);
                              }}
                            />
                          </td>
                        )}
                      <td
                        hidden={resultFigura.Categoria === "JUV"}
                        className="content-fit"
                      >
                        <input
                          type="number"
                          min="1"
                          max="10"
                          style={{ width: "50px" }}
                          value={fig.Penalties.toString()}
                          onChange={(e) => {
                            const updatedData = { ...resultFigura };
                            updatedData.Resultados_fig[index].Penalties =
                              +e.target.value;
                            updatedData.Resultados_fig[index].Media =
                              getAverage(index, true);
                            updatedData.Resultados_fig[index].Pontos =
                              getPontos(index);
                            setResultFigura(updatedData);
                          }}
                        />
                      </td>
                      <td className="content-fit">
                        <input type="number" value={fig.Media} disabled />
                      </td>
                      <td className="content-fit">
                        <input type="number" value={fig.Pontos} disabled />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{resultFigura.Pontos}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <button disabled={load} onClick={saveResultFiguras}>
          Gravar Resultados Figuras
        </button>
      </div>
    </PageLayout>
  );
};

export default ResultFiguras;
